import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";

import Footer from '../components/Footer';
import Swal from 'sweetalert2';
import validate from '../config/util';
import PendingTicket from '../components/PendingTicket';
import ServingTicket from '../components/ServingTicket';
import NoShowTicket from '../components/NoShowTicket';
import WaitTicket from '../components/WaitTicket';
import {useNotification} from 'react-hook-notification';
import Header from "../components/Header";
import * as Constants from "../util/Constant";
import {useTranslation} from "react-i18next";
import {useLocation} from 'react-router-dom/cjs/react-router-dom.min';

const mqtt = require('mqtt/dist/mqtt');
let client = null;

let CryptoJS = require("crypto-js");
const TokenLiveView = ({match}) => {

    let { id } = useParams();

    const [baseUrl, setBaseUrl] = useState('');
    const [config, setConfig] = useState({});

    const [liveTicketDetails, setLiveTicketDetails] = useState(null);
    const [ticketOtherDetails, setTicketOtherDetails] = useState({});
    const [statusController, setStatusController] = useState();
    const [tokenResponse, setTokenResponse] = useState([]);
    const [bookId, setBookId] = useState(0);
    const [fbGiven, setFbGiven] = useState(null);
    const [mqttFound, setmqttFound] = useState(false)

    const location = useLocation();
    // const { addedTicket } = location.state;
    // const { liveTicketData } = location.state;
    const [addedTicketData, setAddedTicketData] = useState(null)
    const [liveTokenData, setLiveTokenData] = useState(null)

    const [exit, setExit] = useState(false);

    const history = useHistory();
    const [branchId, setBranchId] = useState('');
    const [categoryId, setCategoryId] = useState(null);
    const [categoryGroupHas, setCategoryGroupHas] = useState(false)
    const [ticketId, setTicketId] = useState('');
    const [tid, setTID] = useState('');

    const [connected, setConnected] = useState(false);

    const [genToken, setGenToken] = useState(null);

    const notification = useNotification();
    const { t } = useTranslation();

    const [wfs, setWorkFlows] = useState(null);

    // get base url
    useEffect(() => {
        let cipherText = localStorage.getItem(Constants.CONFIG);
        if (cipherText == null) {
            fetch(Constants.CONFIG_PATH).then(response => {
                response.json().then(settings => {
                    localStorage.setItem(Constants.CONFIG, settings.cipher);
                    let bytes = CryptoJS.AES.decrypt(settings.cipher, Constants.ENCRYPT_KEY);
                    let decrypt = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
                    setConfig(decrypt);
                    setBaseUrl(decrypt.API_URL);
                    setGenToken(decrypt.GEN_TOKEN);
                }).catch(error => {
                    console.log("Couldn't find the configurations file");
                    console.error(error);
                })
            });
        } else {
            let bytes = CryptoJS.AES.decrypt(cipherText, Constants.ENCRYPT_KEY);
            let decrypt = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
            setConfig(decrypt);
            setBaseUrl(decrypt.API_URL);
            setGenToken(decrypt.GEN_TOKEN);
        }
    }, [TokenLiveView]);

    // get url params
    useEffect(() => {
        setTicketId(id);
        setBranchId(id.slice(0, 4));
    }, [TokenLiveView]);


    useEffect(() => {
        let intervalId;

        if (connected) {
            getLiveTicketDetails(true, true); // Initial call

            intervalId = setInterval(() => {
                getLiveTicketDetails(true, true);
            }, 5000); // Repeats every 5 seconds
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId); // Cleanup interval on unmount or dependency change
            }
        };
    }, [connected]);

    // show alert
    useEffect(() => {
        if (statusController === 0) {
            showNotification(t('token.alert1'));
        } else if (statusController === 1) {
            showNotification(t('token.alert2'));
        } else if (statusController === 2) {
            showNotification(t('token.alert3'));
        } else if (statusController === 3) {
            showNotification(t('token.alert4'));
        }
    }, [statusController]);


    // call getLiveTicketDetails function
    // useEffect(() => {
    //     if (baseUrl.length > 0 && ticketId.length > 0) {
    //         getLiveTicketDetails(true, false);
    //     }
    // }, [baseUrl, ticketId]);

    // useEffect(() => {
    //     getLiveTicketDetails(true, true);
    // }, [])


    // call - subscribe mqttConnect function
    useEffect(() => {
        if (ticketId !== null && ticketId !== undefined && config !== null && config !== undefined) {
            mqttConnectFromT();
        }
    }, [ticketId, config]);

    useEffect(() => {
        if (categoryId !== null && categoryId !== undefined && config !== null && config !== undefined) {
            mqttConnectFromC();
        }
    }, [categoryId, config]);

    // useEffect(() => {
    //     console.log("live data: ", location.state.categoryName);
    //     setAddedTicketData(location.state.categoryName)
    // }, [location.state.categoryName])

    // useEffect(() => {
    //     console.log("data: ", liveTicketData);
    // }, [liveTicketData])


    useEffect(() => {
        const handleBeforeUnload = () => {
            setExit(true);
        };

        // Add event listener for beforeunload event
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Cleanup function to remove event listener
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    // call - unsubscribe mqttConnect function
    useEffect(() => {
        if (exit) {
            mqttDisconnect();
        }
    }, [exit]);


    useEffect(() => {
        if (liveTicketDetails !== null && liveTicketDetails.tokenStatus === 4) {
            localStorage.removeItem("enc_mobile");
        }
    }, [liveTicketDetails])


    // show notification - function
    function showNotification(text) {
        notification.success({
            title: 'Notification',
            text: text,
            theme: 'light',
            position: 'top-center',
        })
    }

    // auto refresh - subscribe
    function mqttConnectFromT() {

        const host = config.BROKER;

        if (host !== undefined) {
            const clientId = `ws_${Math.random().toString(16).slice(3)}_ts`;

            let options = {
                // protocol: "wss",
                clean: true,
                connectTimeout: 15000,
                useSSL: true,
                // clientId uniquely identifies client
                // choose any string you wish
                username: config.USERNAME,
                password: config.PASSWORD,
                clientId: clientId,
                reconnectPeriod: 1000,
            };
            try {
                client = mqtt.connect(host, options);
                // let topicToken = Constants.TOPIC_TRACK + `${tid}`;
                // let topicCategory = Constants.TOPIC_CATEGORY + `${categoryId}`;

                let topic = ticketId;
                // ws connection
                client.on('connect', () => {
                    // subscribe to topics
                    client.subscribe([topic], () => {
                        // setTimeout(() => {
                        //     getLiveTicketDetails(true, true);
                        // }, 5000);
                        // getLiveTicketDetails(true, true);
                        setConnected(true)
                    });
                    // client.subscribe([topicCategory], () => {
                    //     console.info(`> ` + topicCategory)
                    // });

                });
                client.on("error", (err) => {
                    console.log("err: ", err);
                    console.error(`Connection to ${host} failed ${err}`);
                });

                //on message arrived
                client.on('message', (topic, payload) => {
                    console.log('Received Message:', topic, payload.toString());
                    // if (topic === topic) {
                    //     getLiveTicketDetails(true, true);
                    // }
                    //  else if (topic === topicCategory) {
                    //     getTicketOtherDetails(categoryId);
                    // }
                });
            } catch (e) {
                console.error(`Connection to ${host} failed ${e}`);
            }
        }

    }

    function mqttConnectFromC() {

        const host = config.BROKER;

        if (host !== undefined) {
            const clientId = `ws_${Math.random().toString(16).slice(3)}_ts`;
            let options = {
                clean: true,
                connectTimeout: 15000,
                useSSL: true,
                username: config.USERNAME,
                password: config.PASSWORD,
                clientId: clientId,
                reconnectPeriod: 1000,
            };
            try {
                client = mqtt.connect(host, options);
                let topic = categoryId.toString();

                client.on('connect', () => {
                    client.subscribe([topic], () => {
                        // setTimeout(() => {
                        //     getLiveTicketDetails(true, true);
                        // }, 5000);
                        // getLiveTicketDetails(true, true);
                        setConnected(true);
                    });
                });
                client.on("error", (err) => {
                    console.error(`Connection to ${host} failed ${err}`);
                });

                client.on('message', (topic, payload) => {

                    console.log('Received Message:', topic, payload.toString());
                    // if (topic === topic) {
                    //     getLiveTicketDetails(true, true);
                    // }
                });
            } catch (e) {
                console.error(`Connection to ${host} failed ${e}`);
            }
        }

    }

    // auto refresh - unsubscribe
    function mqttDisconnect() {
        if (client != null) {
            client.unsubscribe(ticketId);
            client.unsubscribe(categoryId);
            // client.unsubscribe(Constants.TOPIC_TRACK + `${ticketId}`);
            // client.unsubscribe(Constants.TOPIC_CATEGORY + `${categoryId}`);
            client.end();
        }
        console.log('topic unsubscribed');
    }

    // getLiveTicketDetails - function
    function getLiveTicketDetails(progress, fromMqtt) {

        // loader
        if (progress && !connected) {
            Swal.fire({
                position: 'center',
                text: "Loading",
                allowOutsideClick: false,
                width: "200px"
            });

            Swal.showLoading();
        }

        axios({
            method: 'GET',
            url: `${baseUrl}/ticket/get-data/token/${id}`,
            headers: {
                Authorization: genToken
            }
        }).then((result) => {
            if (progress) {
                Swal.close();
                window.navigator.vibrate([100, 30, 100, 30, 200]);
            }

            console.log(result.data.data);

            if (result.data.data.length === 0) {
                history.push({
                    pathname: '/link-expired'
                });

            } else {
                let res = result.data.data;

                if (res.tokenStatus === 4) {
                    setConnected(false)
                }

                setCategoryId(res.categoryId)
                setCategoryGroupHas(res.categoryGroup)
                setLiveTicketDetails(res);
            }

        }).catch((error) => {
            console.log(error);
            if (progress) Swal.close();
            const errorMsg = validate(error);
            swtAlert('error', errorMsg);
        })
    }

    // getTicketOtherDetails - function
    function getTicketOtherDetails(serviceId) {
        axios({
            method: 'GET',
            url: `${baseUrl}` + Constants.APPOINTMENT + Constants.VERSION + Constants.GET_LIVE
                + `/${serviceId}/${branchId}/null`,
            headers: {
                'X-AUTH-TOKEN': localStorage.getItem(Constants.TOKEN),
                'Content-Type': 'application/json'
            }
        }).then((result) => {
            setTicketOtherDetails(result.data.data);

        }).catch((error) => {
            console.log("getTicketOtherDetails data fetching error : " + error.response);
            const errorMsg = validate(error)
            swtAlert('error', errorMsg);
        })
    }

    // alert - function
    function swtAlert(icon, text) {
        Swal.fire({
            position: 'center',
            icon: icon,
            text: text,
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: "Dismiss",
            width: '400px',
        })
    }


    return (
        <div>
            {/* <!-- Begin page content --> */}
            <main className='flex-shrink-1 main d-flex align-content-between flex-wrap'>

                {/* Header */}
                <Header />

                {/* {liveTicketDetails.statusController === 0 ?

                    <PendingTicket liveTicketDetails={liveTicketDetails}
                        tokenResponse={tokenResponse}
                        ticketOtherDetails={ticketOtherDetails}
                        wfs={wfs}
                        setWorkFlows={setWorkFlows}
                    />

                    :

                    liveTicketDetails.statusController === 1 ?

                        <ServingTicket tokenResponse={tokenResponse}
                            setFbGiven={setFbGiven}
                            fbGiven={fbGiven}
                            bookId={bookId}
                            liveTicketDetails={liveTicketDetails}
                            wfs={wfs}
                            setWorkFlows={setWorkFlows}
                        />


                        :

                        liveTicketDetails.statusController === 2 ?

                            <NoShowTicket liveTicketDetails={liveTicketDetails}
                                tokenResponse={tokenResponse}
                                ticketOtherDetails={ticketOtherDetails}
                                wfs={wfs}
                                setWorkFlows={setWorkFlows}
                            />

                            :

                            liveTicketDetails.statusController === 3 ?

                                <WaitTicket liveTicketDetails={liveTicketDetails}
                                    tokenResponse={tokenResponse}
                                    ticketOtherDetails={ticketOtherDetails}
                                    wfs={wfs}
                                    setWorkFlows={setWorkFlows}
                                />

                                :


                                liveTicketDetails.statusController === 4 ?

                                    <ServingTicket liveTicketDetails={liveTicketDetails}
                                        bookId={bookId}
                                        fbGiven={fbGiven}
                                        setFbGiven={setFbGiven}
                                        tokenResponse={tokenResponse}
                                        ticketOtherDetails={ticketOtherDetails}
                                        wfs={wfs}
                                        setWorkFlows={setWorkFlows}
                                    />

                                    :

                                    <></>


                } */}

                {liveTicketDetails !== null ? (
                    <>
                        {liveTicketDetails.tokenStatus === 0 || liveTicketDetails.tokenStatus === -2 || liveTicketDetails.tokenStatus === -10 ? (
                            <PendingTicket
                                liveTicketDetails={liveTicketDetails}
                            />
                        ) : liveTicketDetails.tokenStatus === 1 ? (
                            <ServingTicket
                                liveTicketDetails={liveTicketDetails}
                            />
                        ) : liveTicketDetails.tokenStatus === 4 ? (
                            <ServingTicket
                                liveTicketDetails={liveTicketDetails}
                            />
                        ) : liveTicketDetails.tokenStatus === 2 ? (
                            <NoShowTicket
                                liveTicketDetails={liveTicketDetails}
                                getLiveTicketDetails={getLiveTicketDetails}
                            />
                        ) : liveTicketDetails.tokenStatus === 3 ? (
                            <WaitTicket
                                liveTicketDetails={liveTicketDetails}
                            />
                        ) : null}
                    </>
                ) : (
                    null
                )}

                {/* footer */}
                <Footer liveTicketDetails={liveTicketDetails} />

            </main>

        </div>
    )
};

export default TokenLiveView